export const ON_CHANGE_DEBOUNCE_TIMER = 300;

export const PUB_SUB_EVENTS = {
  cartUpdate: 'cart-update',
  quantityUpdate: 'quantity-update',
  variantChange: 'variant-change',
  cartError: 'cart-error'
};

export const product_json = JSON.parse(document.querySelector('.js-product-json')?.innerHTML || '{}');

// Nosto
(function () {
  var name = 'nostojs';
  window[name] =
    window[name] ||
    function (cb) {
      (window[name].q = window[name].q || []).push(cb);
    };
})();

// Custom Nosto integration
if (window.location.href.includes('nosto=')) {
  // Nosto JS API record attribution before changing URL
  let nosto_url_param = window.location.search.slice(window.location.search.indexOf('nosto=') + 6);
  window.nostojs(function (api) {
    api.defaultSession().recordAttribution('vp', `${product_json.id}`, `${nosto_url_param}`).done();
  });
}

// Export the nostojs function for modular usage
export const nostojs = window.nostojs;
